const ApiService = {
  get(resource) {
    // return window.axios.get(`${resource}`).catch(error => {
    //   throw new Error(`[KT] ApiService ${error}`);
    // });
    return window.axios.get(`${resource}`);
  },

  post(resource, params) {
    return window.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return window.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return window.axios.put(`${resource}`, params);
  },

  delete(resource, params) {
    return window.axios.delete(`${resource}`, { data: params });
  },
};

export default ApiService;
