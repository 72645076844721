export default {
  namespaced: true,
  state: () => ({
    groups: [],
    groupsCs: [],
    groupsManager: [],
    groupsManagerCs: [],
    groupsManagerCsClose: [],
    tags: [],
    closeGroupCs: [],
    usersPic: [],
  }),
  mutations: {
    //tags
    addTags(state, payload) {
      state.tags = payload;
    },

    //usersPic
    addUsersPic(state, payload) {
      state.usersPic = payload;
    },

    add(state, payload) {
      state.groups = payload;
    },
    remove(state, { todo }) {
      console.log(todo);
      state.groups = undefined;
    },
    updateGroupName(state, payload) {
      const index = state.groups.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groups[index].name = payload.value;
      }
    },
    updateGroupImage(state, payload) {
      const index = state.groups.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groups[index].photoURL = payload.value;
      }
    },
    updateGroupUsers(state, payload) {
      const index = state.groups.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groups[index].users = payload.value;
      }
    },
    pushRecentMessageReadByGroup(state, payload) {
      const index = state.groups.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groups[index].recentMessage.readBy.push(payload.value);
      }
    },

    //cs
    addCs(state, payload) {
      state.groupsCs = payload;
    },
    addCloseGroupCs(state, payload) {
      state.closeGroupCs = payload;
    },
    removeCs(state, { todo }) {
      console.log(todo);
      state.groupsCs = undefined;
    },
    updateGroupCsName(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].name = payload.value;
      }
    },
    updateGroupCsImage(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].photoURL = payload.value;
      }
    },
    updateGroupCsUsers(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].users = payload.value;
      }
    },
    updateGroupCsTags(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].tags = payload.value;
      }
    },
    pushRecentMessageReadByGroupCs(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].recentMessage.readBy.push(payload.value);
      }
    },
    closeGroupCs(state, payload) {
      const index = state.groupsCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsCs[index].status = "Closed";
      }
    },

    //manager
    addgroupManager(state, payload) {
      state.groupsManager = payload;
    },
    removegroupManager(state, { todo }) {
      console.log(todo);
      state.groupsManager = undefined;
    },
    updateGroupManagerName(state, payload) {
      const index = state.groupsManager.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManager[index].name = payload.value;
      }
    },
    updateGroupManagerImage(state, payload) {
      const index = state.groupsManager.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManager[index].photoURL = payload.value;
      }
    },
    updateGroupManagerUsers(state, payload) {
      const index = state.groupsManager.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManager[index].users = payload.value;
      }
    },

    //manager cs

    addgroupManagerCs(state, payload) {
      state.groupsManagerCs = payload;
    },
    addgroupManagerCsClose(state, payload) {
      state.groupsManagerCsClose = payload;
    },
    removegroupManagerCs(state, { todo }) {
      console.log(todo);
      state.groupsManagerCs = undefined;
    },
    updateGroupManagerCsName(state, payload) {
      const index = state.groupsManagerCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManagerCs[index].name = payload.value;
      }
    },
    updateGroupManagerCsImage(state, payload) {
      const index = state.groupsManagerCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManagerCs[index].photoURL = payload.value;
      }
    },
    updateGroupManagerCsUsers(state, payload) {
      const index = state.groupsManagerCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManagerCs[index].users = payload.value;
      }
    },
    closeGroupManagerCs(state, payload) {
      const index = state.groupsManagerCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        state.groupsManagerCs[index].status = "Closed";
      }
    },
    updateGroupCsMember(state, payload) {
      const index = state.groupsManagerCs.findIndex((x) => x.id === payload.id);
      if (index != -1) {
        let list_member = [];
        list_member[payload.value] = true;
        list_member = Object.assign(
          state.groupsManagerCs[index].members,
          list_member
        );
        state.groupsManagerCs[index].members = list_member;

        //push user
        state.groupsManagerCs[index].users.push(payload.user);
      }
    },
  },
  getters: {
    getUser: (state) => state.groups,
  },
};
