const moment = require("moment-timezone");

const ListConversation = {
    install(Vue) {
        this.EventBus = new Vue();


        // filter date
        Vue.filter("formatUnix", function (value) {
            // moment.locale('vi');
            if (value) {
                return moment.unix(value).format("hh:mm: DD/MM/YYYY");
            }
        });

        Vue.filter("formatDate", function (value) {
            // moment.locale('vi');
            if (value) {
                return moment.unix(value).format("DD-MM-YYYY");
            }
        });
    },
};

export default ListConversation;
