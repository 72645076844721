import Vue from "vue";
import Router from "vue-router";
import AuthService from "./core/services/auth.js";

Vue.use(Router);

const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/view/layout/Layout"),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/view/pages/Dashboard.vue"),
            },
            {
                path: "/builder",
                name: "builder",
                component: () => import("@/view/pages/Builder.vue"),
            },
            {
                path: "/wizard",
                name: "wizard",
                component: () => import("@/view/pages/wizard/Wizard.vue"),
                children: [
                    {
                        path: "wizard-1",
                        name: "wizard-1",
                        component: () => import("@/view/pages/wizard/Wizard-1.vue"),
                    },
                    {
                        path: "wizard-2",
                        name: "wizard-2",
                        component: () => import("@/view/pages/wizard/Wizard-2.vue"),
                    },
                    {
                        path: "wizard-3",
                        name: "wizard-3",
                        component: () => import("@/view/pages/wizard/Wizard-3.vue"),
                    },
                    {
                        path: "wizard-4",
                        name: "wizard-4",
                        component: () => import("@/view/pages/wizard/Wizard-4.vue"),
                    },
                ],
            },
            {
                path: "/test-list",
                name: "TabTestList",
                meta: {title: "Lesson &  Test Management"},
                component: () => import("@/view/pages/tests/TabTestList.vue"),
            },
            {
                path: "/edit-test/:id",
                name: "EditTest",
                meta: {title: "Edit Lesson &  Test Builder"},
                component: () => import("@/view/pages/tests/EditTest.vue"),
                props: true,
            },
            {
                path: "/builder-content/form-builder",
                name: "TestBuilderForm",
                meta: {title: "Lesson &  Test Builder"},
                component: () => import("@/view/pages/tests/AddTest.vue"),
                props: true,
            },
            {
                path: "/coures-list",
                name: "CouresList",
                meta: {title: "Course Management"},
                component: () => import("@/view/pages/courses/CourseList.vue"),
            },
            {
                path: "/announcement-list",
                name: "AnnouncementList",
                meta: {title: "Announcement Management"},
                component: () => import("@/view/pages/announcement/AnnouncementList.vue"),
            },
            {
                path: "/course-content/:id",
                name: "CourseContent",
                component: () => import("@/view/pages/courses/CourseContent.vue"),
                props: true,
            },
            {
                path: "/category-list",
                name: "CategoryList",
                component: () => import("@/view/pages/categories/CategoryList.vue"),
            },
            {
                path: "/tab-marker",
                name: "TabMarker",
                meta: {title: "Assign Markers"},
                component: () =>
                    import("@/view/pages/marking-management/AssignMarker/TabMarker.vue"),
            },
            {
                path: "/marking-recording",
                name: "MarkingRecording",
                component: () =>
                    import("@/view/pages/marking-management/MarkingForRecording.vue"),
            },
            {
                path: "/marking-ticket",
                name: "MarkingTicket",
                meta: {title: "Marking Tickets"},
                component: () =>
                    import("@/view/pages/marking-management/TabMarkingTicket.vue"),
            },
            {
                path: "/marking-ticket/:id",
                name: "MarkingTicketAnswer",
                component: () =>
                    import("@/view/pages/marking-management/MarkingTicketAnswer.vue"),
            },
            {
                path: "/programme-list",
                name: "ProgrammeList",
                meta: {title: "Programme"},
                component: () => import("@/view/pages/programme/ProgrammeList.vue"),
            },
            {
                path: "/programme/:programme_id/faq",
                name: "ProgrammeFaqList",
                meta: {title: "Programme faq list"},
                component: () => import("@/view/pages/programme/FaqQuestionList.vue"),
            },
            // {
            //   path: "/programme-management",
            //   name: "ProgrammeManagement",
            //   meta: { title: 'Programme Management' },
            //   component: () => import("@/view/pages/programme/ProgrammeManagement.vue")
            // },
            {
                path: "/programme-detail/:id",
                name: "ProgrammeDetail",
                meta: {title: "Programme Detail"},
                component: () => import("@/view/pages/programme/ProgrammeDetail.vue"),
                props: true,
            },
            {
                path: "/tab-list",
                name: "TabTest",
                meta: {title: "Test Management"},
                component: () => import("@/view/pages/tests/TabTestList.vue"),
            },
            {
                path: "/partner",
                name: "ListPartner",
                meta: {title: "Partner Management"},
                component: () => import("@/view/pages/partners/ListPartner.vue"),
            },
            {
                path: "/partner-add",
                name: "AddPartner",
                component: () => import("@/view/pages/partners/Add.vue"),
            },
            {
                path: "/partner-edit/:id",
                name: "EditPartner",
                component: () => import("@/view/pages/partners/Edit.vue"),
            },
            {
                path: "/permission",
                name: "PermissionList",
                meta: {title: "Permission Management"},
                component: () => import("@/view/pages/permissions/PermissionList.vue"),
            },
            {
                path: "/role",
                name: "RoleList",
                meta: {title: "Role Management"},
                component: () => import("@/view/pages/roles/RoleList.vue"),
            },
            {
                path: "/role-add",
                name: "AddRole",
                meta: {title: "Add Role"},
                component: () => import("@/view/pages/roles/RoleAdd.vue"),
            },
            {
                path: "/role-edit/:id",
                name: "RoleEdit",
                meta: {title: "Edit Role"},
                component: () => import("@/view/pages/roles/RoleEdit.vue"),
            },
            {
                path: "/student-list",
                name: "StudentList",
                meta: {title: "Student Management"},
                component: () => import("@/view/pages/students/StudentList.vue"),
            },
            {
                path: "/student-add",
                name: "StudentAdd",
                meta: {title: "Add Student"},
                component: () => import("@/view/pages/students/StudentAdd.vue"),
            },
            {
                path: "/student-edit/:id",
                name: "StudentEdit",
                meta: {title: "Edit Student"},
                component: () => import("@/view/pages/students/StudentEdit.vue"),
            },
            {
                path: "/user-list",
                name: "UserList",
                meta: {title: "User Management"},
                component: () => import("@/view/pages/users/UserList.vue"),
            },
            {
                path: "/user-add",
                name: "UserAdd",
                meta: {title: "Add User"},
                component: () => import("@/view/pages/users/UserAdd.vue"),
            },
            {
                path: "/user-edit/:id",
                name: "UserEdit",
                meta: {title: "Edit User"},
                component: () => import("@/view/pages/users/UserEdit.vue"),
            },
            {
                path: "/discount-list",
                name: "TabListDiscount",
                meta: {title: "Discount Management"},
                component: () => import("@/view/pages/discounts/TabListDiscount.vue"),
            },
            {
                path: "/tab-order-statistical",
                name: "TabOrderStatistical",
                meta: {title: "Order Management & Statistical"},
                component: () => import("@/view/pages/orders/TabOrderStatistical.vue"),
            },
            // {
            //   path: "/order-list",
            //   name: "ListOrder",
            //   meta: { title: 'Order Management' },
            //   component: () => import("@/view/pages/orders/ListOrder.vue")

            // },
            {
                path: "/coupon-list",
                name: "CouponList",
                meta: {title: "Coupon Management"},
                component: () => import("@/view/pages/coupons/CouponList.vue"),
            },
            {
                path: "/tag-list",
                name: "TagList",
                meta: {title: "Tag Management"},
                component: () => import("@/view/pages/tags/TagList.vue"),
            },
            {
                path: "/tag-type-list",
                name: "TagTypeList",
                meta: {title: "Tag Type Management"},
                component: () => import("@/view/pages/tag-type/ListTagType.vue"),
            },
            {
                path: "/promise-list",
                name: "PromiseList",
                meta: {title: "Cam kết"},
                component: () => import("@/view/pages/promise/PromiseList.vue"),
            },
            {
                path: "/grading-scale",
                name: "GradingScaleList",
                meta: {title: "Grading Scale"},
                component: () =>
                    import("@/view/pages/grading-scale/GradingScaleList.vue"),
            },
            {
                path: "/grading-scale/:id",
                name: "GradingScaleSkill",
                meta: {title: "Grading Scale Skill"},
                component: () =>
                    import("@/view/pages/grading-scale/GradingScaleSkill.vue"),
            },
            {
                path: "/entry-test",
                name: "EntryTest",
                meta: {title: "Entry Test"},
                component: () => import("@/view/pages/entry-test/EntryTestList.vue"),
            },
            {
                path: "/entry-test-result-analysis",
                name: "ResultAnalysis",
                meta: {title: "Entry Test Result Analysis"},
                component: () => import("@/view/pages/entry-test/ResultAnalysis.vue"),
            },
            {
                path: "/entry-test-result-analysis/:id/detail-feedback",
                name: "DetailFeedback",
                meta: {title: "Detail Feedback Entry Test"},
                component: () => import("@/view/pages/entry-test/DetailFeedback"),
            },
            {
                path: "/entry-test-result-analysis/:id/general-feedback",
                name: "GeneralFeedback",
                meta: {title: "Detail Feedback Entry Test"},
                component: () => import("@/view/pages/entry-test/GeneralFeedback"),
            },
            {
                path: "/teacher-show-web",
                name: "TeacherShowWeb",
                meta: {title: "Hiển thị thông tin giáo viên trên web"},
                component: () => import("@/view/pages/users/TeacherShowWeb.vue"),
            },
            {
                path: "/faq-management",
                name: "FaqCategory",
                meta: {title: "Quản lí FAQ"},
                component: () => import("@/view/pages/faq/TabManagement"),
            },
            {
                path: "/faq-category/:faq_category_id/question",
                name: "FaqQuestion",
                meta: {title: "Danh sách câu hỏi (FAQ)"},
                component: () => import("@/view/pages/faq/FaqQuestion"),
            },
            {
                path: "/high-score-students",
                name: "HighScore",
                meta: {title: "Danh sách học sinh điểm cao"},
                component: () => import("@/view/pages/students/HighScore"),
            },
            {
                path: "/testimonies",
                name: "TestimonyList",
                meta: {title: "Quản lí ý kiến, nhận xét khách hàng"},
                component: () => import("@/view/pages/Testimony/TestimonyList"),
            },
            {
                path: "/product-category",
                name: "ProductCategoryList",
                meta: {title: "Quản lí Product Category"},
                component: () => import("@/view/pages/products/CategoryList.vue"),
            },
            {
                path: "/product-category/:id/sub",
                name: "SubCategoryList",
                meta: {title: "Sub Category List"},
                component: () => import("@/view/pages/products/SubCategoryList"),
            },
            {
                path: "/product-category/:id/target",
                name: "ProductCategoryTarget",
                meta: {title: "Product Category Target"},
                component: () => import("@/view/pages/products/Target"),
            },
            {
                path: "/product-category/:id/level-selector",
                name: "LevelSelector",
                meta: {title: "Level Selector"},
                component: () => import("@/view/pages/products/LevelSelector"),
            },
            {
                path: "/product-category/:id/usp",
                name: "ProductCategoryUSP",
                meta: {title: "Product Category USP"},
                component: () => import("@/view/pages/products/USP"),
            },
            {
                path: "/product-category/:id/banner",
                name: "ProductCategoryBanner",
                meta: {title: "Product Category Banner"},
                component: () => import("@/view/pages/products/Banner"),
            },
            {
                path: "/product-category/:id/course",
                name: "ProductCategoryCourse",
                meta: {title: "Product Category Course"},
                component: () => import("@/view/pages/products/Course"),
            },
            {
                path: "/product-category/:id/topic-vsr",
                name: "ProductCategoryTopVsr",
                meta: {title: "Product Category Topic Vsr"},
                component: () => import("@/view/pages/products/TopicVsrList"),
            },
            {
                path: "/survey",
                name: "SurveyList",
                meta: {title: "Management Survey"},
                component: () => import("@/view/pages/survey2/SurveyList.vue"),
            },
            {
                path: "/survey/:id/question",
                name: "QuestionSurvey",
                meta: {title: "Question survey"},
                component: () => import("@/view/pages/survey2/Question/QuestionItem"),
            },
            {
                path: "/information",
                name: "ListInformation",
                meta: {title: "Quản lí thông tin prep"},
                component: () => import("@/view/pages/information/ListInformation.vue"),
            },
            {
                path: "/instant-feedback",
                name: "InstantFeedbackList",
                meta: {title: "Quản lí instant feedback"},
                component: () =>
                    import("@/view/pages/instant-feedback/InstantFeedbackList"),
            },
            {
                path: "/instant-feedback/:id/detail",
                name: "InstantFeedbackDetail",
                meta: {title: "instant feedback detail"},
                component: () => import("@/view/pages/instant-feedback/Detail"),
            },
            {
                path: "/top-banner",
                name: "TopBannerList",
                meta: {title: "Quản lí top banner"},
                component: () => import("@/view/pages/top-banner/TopBannerList.vue"),
            },
            {
                path: "/top-message",
                name: "TopMessageList",
                meta: {title: "Quản lí top message"},
                component: () => import("@/view/pages/top-message/TopMessageList.vue"),
            },
            {
                path: "/news",
                name: "NewsList",
                meta: {title: "Quản lí bài báo về prep"},
                component: () => import("@/view/pages/news/NewsList"),
            },
            {
                path: "/payment-method-guide",
                name: "PaymentGuide",
                meta: {title: "Quản lí hướng dẫn thanh toán"},
                component: () => import("@/view/pages/payment-method-guide/List"),
            },
            {
                path: "/SEO-information",
                name: "SEOList",
                meta: {title: "Quản lí SEO"},
                component: () => import("@/view/pages/seo/SEOList"),
            },
            {
                path: "/video-management",
                name: "VideoList",
                component: () => import("@/view/pages/element/VideoList"),
            },
            {
                path: "/list-marker-config",
                name: "ListMarkerConfig",
                component: () => import("@/view/pages/marker-config/ListMarkerConfig"),
            },
            {
                path: "/conversation-tag-list",
                name: "ConversationTagList",
                meta: {title: "Tag Type Management"},
                component: () => import("@/view/pages/conversation-tag/index.vue"),
            },
            {
                path: "/marker-history",
                name: "MarkerHistory",
                component: () =>
                    import("@/view/pages/marking-management/MarkerHistory"),
            },
            {
                path: "/statistical-deadline-ticket",
                name: "StatisticalDeadlineTicket",
                component: () =>
                    import("@/view/pages/marking-management/StatisticalDeadline/Tab"),
            },
            {
                path: "/category-material",
                name: "CategoryMaterialList",
                component: () => import("@/view/pages/material/category/List"),
            },
            {
                path: "/category-material/:id/material",
                name: "MaterialList",
                component: () => import("@/view/pages/material/MaterialList"),
            },
            {
                path: "/service-package-list",
                name: "ServicePackageList",
                component: () => import("@/view/pages/service-package/List"),
            },
            // router module chat
            {
                path: "/chat",
                name: "Chat",
                component: () => import("@/view/pages/chat/index.vue"),
            },
            {
                path: "/chat/import",
                name: "ChatImport",
                component: () => import("@/view/pages/chat/import.vue"),
            },
            {
                path: "/chat/manager",
                name: "ChatManager",
                component: () => import("@/view/pages/chat/manager.vue"),
            },
            {
                path: "/chat/cs",
                name: "ChatCS",
                component: () => import("@/view/pages/chat/chatcs.vue"),
            },
            {
                path: "/chat/managerCS",
                name: "ChatManagerCS",
                component: () => import("@/view/pages/chat/managerCS.vue"),
            },
            // end route chat
            {
                path: "/coupon-group-list",
                name: "CouponGroupList",
                meta: {title: "Coupon Group Management"},
                component: () => import("@/view/pages/coupon-group/GroupList.vue"),
            },
            {
                path: "/roadmap-list",
                name: "RoadmapList",
                meta: {title: "Roadmap Management"},
                component: () => import("@/view/pages/roadmap/List.vue"),
            },
            {
                path: "/roadmap-step/:id",
                name: "RoadmapStep",
                meta: {title: "Roadmap Step Management"},
                component: () => import("@/view/pages/roadmap/StepList.vue"),
            },
            {
                path: "/activation-code-list",
                name: "ActivationCodeList",
                meta: {title: "Activation Code Management"},
                component: () =>
                    import("@/view/pages/activation-codes/ActivationCodeList.vue"),
            },
            {
                path: "/test-set-list",
                name: "TestSetList",
                meta: {title: "Test Set Management"},
                component: () => import("@/view/pages/test-set/TestSetTab.vue"),
            },
            {
                path: "/popup-list",
                name: "PopupList",
                meta: {title: "Popup Management"},
                component: () => import("@/view/pages/web-popup/PopupList.vue"),
            },
            {
                path: "/test-set-test/:id/:model",
                name: "TestSetTest",
                meta: {title: "Test Set Test Management"},
                component: () => import("@/view/pages/test-set/TestSetTest.vue"),
            },
            {
                path: "/product-category/:id/study-plan",
                name: "StudyPlanList",
                meta: {title: "Study Plan Management"},
                component: () => import("@/view/pages/study-plan/StudyPlanList"),
            },
            {
                path: "/study-plan/:id/unit",
                name: "StudyPlanUnit",
                meta: {title: "Study Plan Unit"},
                component: () => import("@/view/pages/study-plan/StudyPlanUnit"),
            },
            {
                path: "/chat-with-student",
                name: "chatWithStudent",
                component: () => import("@/view/pages/chat-with-students/ChatWithStudentList.vue"),
            },
        ],
    },
    {
        path: "/error",
        name: "error",
        component: () => import("@/view/pages/error/Error.vue"),
        children: [
            {
                path: "error-1",
                name: "error-1",
                component: () => import("@/view/pages/error/Error-1.vue"),
            },
            {
                path: "error-2",
                name: "error-2",
                component: () => import("@/view/pages/error/Error-2.vue"),
            },
            {
                path: "error-3",
                name: "error-3",
                component: () => import("@/view/pages/error/Error-3.vue"),
            },
            {
                path: "error-4",
                name: "error-4",
                component: () => import("@/view/pages/error/Error-4.vue"),
            },
            {
                path: "error-5",
                name: "error-5",
                component: () => import("@/view/pages/error/Error-5.vue"),
            },
            {
                path: "error-6",
                name: "error-6",
                component: () => import("@/view/pages/error/Error-6.vue"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/view/pages/auth/Auth"),
        children: [
            {
                name: "login",
                path: "/login",
                component: () => import("@/view/pages/auth/Login"),
            },
            {
                name: "register",
                path: "/register",
                component: () => import("@/view/pages/auth/Register"),
            },
        ],
    },
    {
        path: "*",
        redirect: "/404",
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/view/pages/error/Error-1.vue"),
    },
    {
        // the 404 route, when none of the above matches
        path: "/403",
        name: "403",
        component: () => import("@/view/pages/error/Error-3.vue"),
    },
];

const router = new Router({
    routes,
    mode: "history",
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    //   If the next route is requires user to be Logged IN
    if (to.matched.some((m) => m.meta.requiresAuth)) {
        return AuthService.check().then((authenticated) => {
            if (!authenticated) {
                return next({
                    path: "/login",
                });
            }

            return next();
        });
    }

    return next();
});

export default router;
