export default {
  namespaced: true,
  state: () => ({
    user: undefined,
    chatMembers: undefined,
  }),
  mutations: {
    add(state, { uid, displayName, photoURL, email, groups }) {
      state.user = {
        uid,
        displayName,
        photoURL,
        email,
        groups,
      };
    },
    remove(state, payload) {
      console.log(payload);
      state.user = undefined;
    },
    addChatMembers(state, chatMembers) {
      state.chatMembers = chatMembers;
    },
    removeChatMembers(state) {
      state.chatMembers = undefined;
    },
  },
  getters: {
    getUser: (state) => state.user,
    getChatMembers: (state) => state.chatMembers,
  },
};
